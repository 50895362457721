/**
 * Get the color
 *
 * Get color from $color-variations map, inspired by WordPress and his the_title/get_the_title functions.
 * 
 * Thanks to Hugo Giraudel, as usual, master of Sass. 😊

 * @access  public
 * @author  Jérémy Levron <levronjeremy@19h47.fr>
 * @edited for Couleur Citron  Benjamin Costes <https://twitter.com/benjam1nC>
 */
@function color( $keys... ) {
    $map: $color-variations;
    $color-base: map-get($map, nth($keys, 1));
    $result: null;

    @if length( $keys ) == 1 {

        $result: $color-base;
    }

    @if length( $keys ) == 2 {

        $color-key: nth($keys, 1);
        $color-value: nth($keys, 2);

        $result: map-get($color-base, $color-value);
    }

    @return $result;
}