@charset "UTF-8";
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

/**
 * Get the color
 *
 * Get color from $color-variations map, inspired by WordPress and his the_title/get_the_title functions.
 * 
 * Thanks to Hugo Giraudel, as usual, master of Sass. 😊

 * @access  public
 * @author  Jérémy Levron <levronjeremy@19h47.fr>
 * @edited for Couleur Citron  Benjamin Costes <https://twitter.com/benjam1nC>
 */
@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter-thin.woff2") format("woff2"), url("../../fonts/inter-thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter-light.woff2") format("woff2"), url("../../fonts/inter-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter-regular.woff2") format("woff2"), url("../../fonts/inter-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter-medium.woff2") format("woff2"), url("../../fonts/inter-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Syne";
  src: url("../../fonts/syne-regular.woff2") format("woff2"), url("../../fonts/syne-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Syne";
  src: url("../../fonts/syne-bold.woff2") format("woff2"), url("../../fonts/syne-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

img {
  border: none;
  display: block;
  outline: none;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
  border: none;
}

ul, li {
  list-style-type: none;
}

textarea {
  resize: vertical;
}

header, footer, nav, section, article {
  display: block;
  position: relative;
}

html {
  font-size: 10px;
  overflow-y: auto;
}

body {
  line-height: normal;
  width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

input, textarea, select, option {
  outline: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 64px;
}

body {
  width: 100vw;
  overflow: hidden;
  padding-top: 64px;
  color: #283333;
  font-family: "Inter";
  font-size: 1.6rem;
  font-weight: 300;
}

.container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 20px;
}
.container--small {
  max-width: 770px;
}

.title {
  font-family: "Syne";
  text-align: left;
  line-height: 1;
}
.title strong {
  font-weight: 700;
}
.title--h1 {
  font-size: 5rem;
}
@media only screen and (max-width: 767px) {
  .title--h1 {
    font-size: 3.5rem;
  }
}
.title--h2 {
  font-size: 3.2rem;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .title--h2 {
    font-size: 2.3rem;
  }
}
.title--h3 {
  font-size: 2.4rem;
}
@media only screen and (max-width: 767px) {
  .title--h3 {
    font-size: 1.8rem;
  }
}

.subtitle {
  font-family: "Syne";
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
  color: #283333 !important;
  font-family: "Inter" !important;
  font-size: 1.6rem !important;
  font-weight: 300 !important;
}

#ot-sdk-cookie-policy .ot-sdk-cookie-policy.ot-sdk-container #cookie-policy-title,
#ot-sdk-cookie-policy .ot-sdk-cookie-policy.ot-sdk-container .ot-sdk-cookie-policy-group {
  font-family: "Syne" !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: unset !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
  background: #F7F0EB !important;
}

[data-youtube],
[data-sound],
[data-video] {
  cursor: pointer;
}

.youtube {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translate(0, 10%);
  pointer-events: none;
  transition: opacity 0.3s ease-out, transform 0.85s cubic-bezier(0.37, 0.75, 0, 1);
}
.youtube.js-open {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}
.youtube__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.youtube__close span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 1px;
  background: #ffffff;
  transform: translate(-50%, -50%) rotate(45deg);
}
.youtube__close span:first-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.youtube__overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #283333;
  opacity: 0.8;
  cursor: pointer;
}
.youtube__iframe {
  position: relative;
  aspect-ratio: 16/9;
}
.youtube__iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sound,
.videopopin {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translate(0, 10%);
  pointer-events: none;
  transition: opacity 0.3s ease-out, transform 0.85s cubic-bezier(0.37, 0.75, 0, 1);
}
.sound.js-open,
.videopopin.js-open {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}
.sound__close,
.videopopin__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.sound__close span,
.videopopin__close span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 1px;
  background: #ffffff;
  transform: translate(-50%, -50%) rotate(45deg);
}
.sound__close span:first-child,
.videopopin__close span:first-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.sound__overlay,
.videopopin__overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #283333;
  opacity: 0.8;
  cursor: pointer;
}
.sound__player,
.videopopin__player {
  position: relative;
}
.sound__player audio,
.sound__player video,
.videopopin__player audio,
.videopopin__player video {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.footer {
  padding: 10px 0;
  background: #686E63;
}
.footer .container {
  position: relative;
}
.footer__link {
  display: block;
  padding: 15px !important;
  border: 0 !important;
  background: transparent !important;
  color: #ffffff !important;
  font-size: 1.4rem !important;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .footer__link {
    padding: 5px 15px;
  }
}
.footer__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .footer__list {
    display: block;
    margin-top: 10px;
    text-align: center;
  }
}
.footer svg, .footer__image {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  max-width: 200px;
  transform: translate(0, -50%);
}
@media only screen and (max-width: 1024px) {
  .footer svg, .footer__image {
    display: block;
    position: relative;
    top: auto;
    margin: 0 auto;
    transform: none;
  }
}

#divpopup {
  border-radius: 5px;
}

.close {
  position: absolute;
  right: 23px;
  top: 10px;
  width: 16px;
  height: 16px;
  opacity: 0.3;
  border: none;
  background: unset;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.filter-button:hover {
  background-color: white;
  color: black;
}

.filter-button {
  color: white;
  background: #004a88;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  font-size: 8pt;
  padding: 0;
  margin: 5px;
  border: 3px solid rgba(0, 0, 0, 0.5019607843);
}

.text-font {
  font-family: sans-serif;
}

button.text-font {
  background: #004a88;
  color: white;
  font-weight: lighter;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.nav {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  font-family: "Syne";
  font-weight: 400;
}
.nav__burger {
  display: none;
  background: transparent;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .nav__burger {
    display: block;
  }
}
.nav__burger span {
  display: block;
  width: 20px;
  height: 1px;
  margin: 4px 0 0 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  background: #283333;
}
.nav__burger span:nth-child(3) {
  margin: -1px 0 0 0;
}
.nav__container {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .nav__container {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    background: #ffffff;
  }
}
.nav__language {
  display: flex;
  margin: 0 0 0 20px;
}
@media only screen and (max-width: 1024px) {
  .nav__language {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
}
.nav__languageItem {
  display: block;
  padding: 8px;
}
.nav__list {
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .nav__list {
    display: block;
  }
}
.nav__listLink {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  padding: 12px 60px;
  color: #283333;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.25;
}
.nav__listLink--colored {
  background: #283333;
  color: #ffffff;
}
@media only screen and (max-width: 1024px) {
  .nav__listLink {
    justify-content: center;
  }
}
.nav__listItem {
  position: relative;
}
.nav__listItem:not(:first-child) .nav__listLink:not(.nav__listItem--colored)::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 1px;
  height: calc(100% - 16px);
  background: #283333;
  opacity: 0.3;
}
@media only screen and (min-width: 1024px) {
  .nav__listItem:hover .nav__listSub {
    opacity: 1;
    transform: translate(0, 1px);
    pointer-events: all;
  }
}
.nav__listItem.js-open .nav__listSub {
  display: block;
}
.nav__listSub {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  padding: 12px 0;
  background: #ffffff;
  opacity: 0;
  transform: translate(0, -20px);
  pointer-events: none;
  transition: transform 0.85s cubic-bezier(0.37, 0.75, 0, 1), opacity 0.3s ease-out;
}
@media only screen and (max-width: 1024px) {
  .nav__listSub {
    display: none;
    position: relative;
    top: auto;
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
}
.nav__listSubItem:not(:first-child)::before {
  content: "";
  display: block;
  width: calc(100% - 80px);
  margin: 0 auto;
  height: 1px;
  background: #283333;
  opacity: 0.2;
}
.nav__listSubLink {
  display: block;
  padding: 10px 40px;
  color: #283333;
  font-size: 1.5rem;
  white-space: nowrap;
}
@media only screen and (max-width: 1024px) {
  .nav__listSubLink {
    text-align: center;
  }
}
.nav__logo {
  display: block;
  padding: 5px 0;
}
.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav.js-open .nav__burger span:nth-child(1), .nav.js-open .nav__burger span:nth-child(4) {
  opacity: 0;
}
.nav.js-open .nav__burger span:nth-child(2) {
  transform: rotate(45deg);
}
.nav.js-open .nav__burger span:nth-child(3) {
  transform: rotate(-45deg);
}
.nav.js-open .nav__container {
  display: block;
}

.aside {
  padding: 10px 0;
  background: #F7F0EB;
  font-size: 1.5rem;
  font-weight: 400;
  font-style: italic;
}
.aside__img {
  display: block;
  width: 100px;
  margin: 0 30px 0 0;
  mix-blend-mode: multiply;
}
.aside__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aside__wrapper p {
  text-align: center;
}

.header {
  background: #F7F0EB;
}
.header__cite {
  padding: 0 0 0 20px;
  border-left: 1px solid #686E63;
  font-weight: 500;
}
.header__col {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .header__col {
    width: 50%;
  }
}
.header__col--content {
  padding: 20px 20px 0 20px;
}
@media only screen and (min-width: 1024px) {
  .header__col--content {
    padding: 40px 30px;
  }
}
.header__col--image {
  height: 580px;
}
@media only screen and (max-width: 1024px) {
  .header__col--image {
    height: auto;
  }
}
.header__content {
  margin: 20px 0 0 0;
  padding: 0 0 40px 0;
  text-align: justify;
}
@media only screen and (min-width: 1024px) {
  .header__content {
    padding: 0 0 0 50px;
  }
}
.header__content p:not(:first-child) {
  margin-top: 20px;
}
.header__deco {
  position: absolute;
  top: -10px;
  right: 0;
  width: 100%;
}
.header__img {
  width: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 1024px) {
  .header__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    max-width: none;
    height: 100%;
  }
}
.header__wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
}

.app {
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .app {
    padding: 120px 0 80px;
  }
}
.app__close {
  right: -60px !important;
  left: auto !important;
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .app__close {
    top: -50px !important;
    right: 10px !important;
  }
}
.app__close span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 1px;
  background: #ffffff;
  transform: translate(-50%, -50%) rotate(45deg);
}
.app__close span:first-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.app__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .app__col {
    width: 33.3333333333%;
  }
  .app__col--large {
    width: 66.6666666667%;
  }
}
.app__content {
  padding: 40px 0 0 0;
}
@media only screen and (min-width: 1024px) {
  .app__content {
    padding: 0 0 0 60px;
  }
}
.app__cta {
  display: inline-flex;
  align-items: center;
  margin: 30px 0 0 0;
  padding: 10px 20px;
  background: #283333;
  color: #ffffff;
  font-family: "Syne";
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.app__cta svg {
  display: block;
  width: 20px;
  height: auto;
  margin: 0 0 0 10px;
  transform: translate(0, 1px);
}
.app__img {
  width: 100%;
}
.app__module {
  width: 100%;
}
.app__popin {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(40, 51, 51, 0.8);
  transition: opacity 0.3s ease-out, transform 0.85s cubic-bezier(0.37, 0.75, 0, 1);
}
.app__popinContainer {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 90vh;
  background: #ffffff;
}
@media only screen and (max-width: 767px) {
  .app__popinContainer {
    height: 80vh;
  }
}
.app__popinContainer > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.app__popin:not(.js-visible) {
  opacity: 0;
  transform: translate(0, 10%);
  pointer-events: none;
}
.app__text {
  margin: 20px 0 0 0;
  text-align: justify;
}
.app__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.cite {
  padding: 20px 0 40px;
}
@media only screen and (min-width: 1024px) {
  .cite {
    padding: 20px 0 60px;
  }
}
.cite__content {
  font-weight: 500;
  text-align: center;
}
.cite--soft {
  padding: 40px 0;
  background: #F7F0EB;
}
@media only screen and (min-width: 1024px) {
  .cite--soft {
    padding: 60px 0;
  }
}
.cite .title {
  text-align: center;
}

.temoignage {
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .temoignage {
    padding: 80px 0 40px;
  }
}
.temoignage__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .temoignage__col {
    width: calc(50% - 15px);
  }
}
@media only screen and (max-width: 1024px) {
  .temoignage__col:first-child {
    margin-bottom: 40px;
  }
}
.temoignage__content {
  text-align: justify;
}
.temoignage__content p:not(:first-child) {
  margin-top: 20px;
}
.temoignage__podcast {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0 0;
  padding: 0 20px;
  background: #283333;
}
.temoignage__video {
  position: relative;
  margin: 0 0 20px 0;
  cursor: pointer;
}
.temoignage__video::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #283333;
  opacity: 0.3;
  mix-blend-mode: multiply;
}
.temoignage__video svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.temoignage__video img {
  width: 100%;
}
.temoignage__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
}
.temoignage .title--h3 {
  padding: 0 0 10px 45px;
  background: url("../../img/subtitle.svg") left 20px center no-repeat;
  background-size: 9px auto;
}

.heading {
  padding: 40px 0;
  overflow: hidden;
  background: #283333;
}
@media only screen and (min-width: 1024px) {
  .heading {
    padding: 60px 0;
  }
}
.heading__deco {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -45%);
}
.heading .title {
  color: #ffffff;
}
.heading--dash {
  border-top: 40px solid #ffffff;
}

.investigation {
  padding: 20px 0;
}
@media only screen and (min-width: 1024px) {
  .investigation {
    padding: 40px 0;
  }
}
.investigation__col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0;
  column-gap: 30px;
}
@media only screen and (min-width: 1024px) {
  .investigation__col {
    padding: 40px 0;
  }
}
.investigation__colItem {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .investigation__colItem {
    width: calc(50% - 15px);
  }
}
.investigation__colItem--figure {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  margin: 30px 0 0 0;
}
@media only screen and (min-width: 1024px) {
  .investigation__colItem--figure {
    margin: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .investigation__colItem:not(:first-child) .investigation__video {
    margin-top: 30px;
  }
}
.investigation__content {
  text-align: justify;
}
.investigation__content p:not(:first-child) {
  margin: 20px 0 0 0;
}
.investigation__figure {
  width: calc(50% - 15px);
}
.investigation__figureItem {
  padding: 20px;
  background: #283333;
  color: #ffffff;
  text-align: center;
}
.investigation__figureContent {
  font-size: 1.2rem;
}
.investigation__figureNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  font-family: "Syne";
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
}
.investigation__figureNumber span {
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
}
.investigation__figureImg {
  position: relative;
  margin: 20px 0 0 0;
  aspect-ratio: 255/306;
}
.investigation__figureImg:first-child {
  margin: 0 0 20px 0;
  aspect-ratio: 255/289;
}
.investigation__figureImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.investigation__intro {
  display: flex;
  padding: 20px 0;
}
@media only screen and (min-width: 1024px) {
  .investigation__intro {
    padding: 40px 0;
  }
}
@media only screen and (max-width: 1024px) {
  .investigation__intro {
    flex-wrap: wrap;
  }
}
.investigation__introContent {
  text-align: justify;
}
.investigation__intro svg {
  display: block;
  flex: 1 0 100%;
  height: auto;
  margin: 0 0 20px 0;
}
@media only screen and (min-width: 1024px) {
  .investigation__intro svg {
    flex: 0 0 250px;
    margin: 0 30px 0 0;
  }
}
.investigation__label {
  margin: 0 0 0 20px;
  padding: 0 0 0 15px;
  border-left: 1px solid #283333;
  font-size: 1.2rem;
}
.investigation__video {
  cursor: pointer;
}
.investigation__video img {
  width: 100%;
  margin: 0 0 20px 0;
  aspect-ratio: 540/300;
  object-fit: cover;
}

.figure {
  background: #283333;
  color: #ffffff;
}
.figure__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .figure__col {
    width: 50%;
  }
}
.figure__col--content {
  display: flex;
  align-items: center;
}
.figure__content {
  padding: 30px 20px;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .figure__content {
    padding: 30px 30px 30px 15px;
  }
}
.figure__content .title {
  text-align: center;
}
.figure__list {
  display: flex;
  flex-wrap: wrap;
}
.figure__listItem {
  width: 100%;
  margin: 0 0 30px 0;
  padding: 0 15px;
}
@media only screen and (min-width: 1024px) {
  .figure__listItem {
    width: 33.3333333333%;
  }
  .figure__listItem--large {
    width: 66.6666666667%;
  }
  .figure__listItem:nth-child(2), .figure__listItem:nth-child(3), .figure__listItem:nth-child(7), .figure__listItem:nth-child(8) {
    position: relative;
  }
  .figure__listItem:nth-child(2)::before, .figure__listItem:nth-child(3)::before, .figure__listItem:nth-child(7)::before, .figure__listItem:nth-child(8)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 60px;
    background: #686E63;
  }
}
@media only screen and (max-width: 1024px) {
  .figure__listItem:not(.figure__list--border):nth-child(1) {
    order: 0;
  }
  .figure__listItem:not(.figure__list--border):nth-child(2) {
    order: 1;
  }
  .figure__listItem:not(.figure__list--border):nth-child(3) {
    order: 2;
  }
  .figure__listItem:not(.figure__list--border):nth-child(4) {
    order: 3;
  }
  .figure__listItem:not(.figure__list--border):nth-child(5) {
    order: 5;
  }
  .figure__listItem:not(.figure__list--border):nth-child(6) {
    order: 4;
  }
  .figure__listItem:not(.figure__list--border):nth-child(7) {
    order: 6;
  }
  .figure__listItem:not(.figure__list--border):nth-child(8) {
    order: 7;
  }
}
.figure__listItem--text {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 1024px) {
  .figure__listItem--text {
    margin-top: 30px;
  }
}
.figure__listContent {
  font-size: 1.2rem;
}
.figure__listContent--large {
  font-size: 1.6rem;
}
.figure__listNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  font-family: "Syne";
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
}
.figure__listNumber span {
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
}
.figure__list--border .figure__listItem:nth-child(5), .figure__list--border .figure__listItem:nth-child(6) {
  position: relative;
}
.figure__list--border .figure__listItem:nth-child(5)::before, .figure__list--border .figure__listItem:nth-child(6)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 60px;
  background: #686E63;
}
@media only screen and (max-width: 1024px) {
  .figure__list--border .figure__listItem:nth-child(5)::before, .figure__list--border .figure__listItem:nth-child(6)::before {
    content: none;
  }
}
.figure__img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
@media only screen and (min-width: 1024px) {
  .figure__img {
    width: 50vw;
    max-width: none;
    float: right;
  }
}
.figure__text {
  margin: 30px 0 15px 0;
}
@media only screen and (min-width: 1024px) {
  .figure__text {
    padding: 0 65px;
  }
}
.figure__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.testimony {
  position: relative;
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .testimony {
    padding: 80px 0;
  }
}
.testimony__blockquote {
  margin: 30px 0 0 0;
}
.testimony__blockquoteAuthor {
  font-family: "Syne";
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.testimony__blockquotePosition {
  display: block;
  margin: 10px 0 0 0;
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  text-transform: none;
}
.testimony__blockquoteText {
  margin: 0 0 30px 0;
  padding: 0 0 0 20px;
  border-left: 1px solid #283333;
  font-size: 1.5rem;
  text-align: justify;
  line-height: 1.6;
}
.testimony__content {
  position: relative;
  width: 100%;
  padding: 30px 20px 0;
  background: #ffffff;
}
@media only screen and (min-width: 1024px) {
  .testimony__content {
    width: 50%;
    padding: 60px 95px;
  }
}
.testimony__deco {
  position: absolute;
  top: -15px;
  left: 50px;
}
@media only screen and (min-width: 1024px) {
  .testimony__deco {
    left: 90px;
  }
}
.testimony__img {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .testimony__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }
}

.faq {
  padding: 40px 0;
  background: #F7F0EB;
}
@media only screen and (min-width: 1024px) {
  .faq {
    padding: 60px 0;
  }
}
.faq .title {
  margin: 0 0 10px 0;
  text-align: center;
}
.faq__content {
  padding: 0 0 20px 0;
  text-align: justify;
}
.faq__item {
  border-bottom: 1px solid #283333;
  font-size: 1.4rem;
}
.faq__item.js-open .faq__title::after {
  transform: translate(0, -50%) rotate(180deg);
}
.faq__title {
  position: relative;
  padding: 20px 0;
  cursor: pointer;
}
.faq__title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 12px;
  height: 12px;
  background: url("../../img/illustration/faq.svg") center center no-repeat;
  transition: transform 0.85s cubic-bezier(0.37, 0.75, 0, 1);
  transform: translate(0, -50%);
}
.faq__wrapper {
  height: 0;
  overflow: hidden;
}

.social {
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .social {
    padding: 80px 0;
  }
}
.social__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .social__col {
    width: calc(50% - 15px);
  }
}
.social__content {
  text-align: justify;
}
.social__content p {
  line-height: 1.6;
}
.social__content p:not(:first-child) {
  margin-top: 20px;
}
.social__label {
  margin: 0 0 0 20px;
  padding: 0 0 0 15px;
  border-left: 1px solid #283333;
  font-size: 1.2rem;
}
.social__video {
  position: relative;
  margin: 0 0 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .social__video {
    margin-top: 20px;
  }
}
.social__videoImg {
  width: 100%;
}
.social__video svg {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.social__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
}

.podcast {
  margin: 40px 0 0 0;
  padding: 40px 0;
  background: #283333;
  color: #ffffff;
}
@media only screen and (min-width: 1024px) {
  .podcast {
    margin: 70px 0 0 0;
    padding: 60px 0;
  }
}
.podcast__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .podcast__col {
    width: calc(50% - 15px);
  }
}
.podcast__img {
  margin: -80px auto 20px;
  cursor: pointer;
}
@media only screen and (min-width: 1024px) {
  .podcast__img {
    margin: -130px auto -60px;
  }
}
.podcast__subtitle {
  margin: 0 0 10px 0;
  color: #686E63;
  font-family: "Syne";
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.6;
  text-transform: uppercase;
}
.podcast__text {
  margin: 20px 0 0 0;
  text-align: justify;
}
.podcast__wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.all {
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .all {
    padding: 80px 0;
  }
}
.all__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .all__col {
    width: calc(50% - 15px);
  }
}
.all__content {
  text-align: justify;
}
@media only screen and (min-width: 1024px) {
  .all__content {
    margin-top: 65px;
  }
}
.all__content p:not(:first-child) {
  margin-top: 20px;
}
.all__figure {
  display: flex;
  align-items: flex-end;
  column-gap: 30px;
}
@media only screen and (max-width: 1024px) {
  .all__figure {
    margin-top: 20px;
  }
}
.all__figureItem {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .all__figureItem {
    width: calc(40% - 15px);
  }
  .all__figureItem--large {
    width: calc(60% - 15px);
  }
}
.all__figureItem--figure {
  padding: 10px 20px 20px 20px;
  background: #283333;
  color: #ffffff;
  text-align: center;
}
.all__figureContent {
  font-size: 1.2rem;
}
.all__figureNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  font-family: "Syne";
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
}
.all__figureNumber span {
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
}
.all__figureImg {
  width: 100%;
}
.all__figureLabel {
  margin: 20px 0 0 20px;
  padding: 0 0 0 15px;
  border-left: 1px solid #283333;
  font-size: 1.2rem;
}
.all__figureLabel strong {
  font-weight: 500;
}
.all__video {
  margin: 30px 0 0 0;
}
.all__videoImg {
  width: 100%;
  cursor: pointer;
}
.all__videoLabel {
  margin: 20px 0 0 20px;
  padding: 0 0 0 15px;
  border-left: 1px solid #283333;
  font-size: 1.2rem;
}
.all__videoLabel strong {
  font-weight: 500;
}
.all__wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.application {
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .application {
    padding: 80px 0;
  }
}
.application__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .application__col {
    width: calc(50% - 15px);
  }
}
.application__content {
  text-align: justify;
}
.application__content p:not(:first-child) {
  margin-top: 20px;
}
.application__cta {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background: #283333;
  color: #ffffff;
  font-family: "Syne";
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.application__cta svg {
  display: block;
  width: 20px;
  height: auto;
  margin: 0 0 0 10px;
  transform: translate(0, 1px);
}
.application__img {
  width: 100%;
  margin: 40px 0;
}
.application__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
}

.reaction {
  padding: 0 0 40px 0;
}
@media only screen and (min-width: 1024px) {
  .reaction {
    padding: 0 0 80px 0;
  }
}
.reaction__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .reaction__col {
    width: calc(50% - 15px);
  }
}
.reaction__img {
  margin: 25px 0 0 0;
}
@media only screen and (max-width: 1024px) {
  .reaction__img {
    margin-right: auto;
    margin-left: auto;
  }
}
.reaction__slider {
  padding-top: 15px;
  transition-timing-function: cubic-bezier(0.37, 0.75, 0, 1);
}
.reaction__sliderContent {
  position: relative;
  margin: 0 0 40px 0;
  padding: 30px;
  border: 1px solid #F7F0EB;
  font-size: 2rem;
  line-height: 1.5;
}
.reaction__sliderContent svg {
  left: 30px;
}
.reaction__sliderContent svg path {
  fill: #F7F0EB;
}
.reaction__sliderContent--green {
  background: #686E63;
  color: #ffffff;
}
.reaction__sliderContent--green svg path {
  fill: #283333;
}
.reaction__sliderContent--beige {
  background: #F7F0EB;
}
.reaction__sliderContent--beige svg path {
  fill: #686E63;
}
.reaction__sliderContent--dark {
  background: #283333;
  color: #ffffff;
}
.reaction__sliderContent--dark svg path {
  fill: #686E63;
}
@media only screen and (min-width: 1024px) {
  .reaction__sliderContent:nth-child(2) {
    transform: translate(90px, 0);
  }
}
.reaction__sliderItem {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}
.reaction__sliderItem.tns-slide-active, .reaction__sliderItem.tns-slide-active ~ .reaction__sliderItem {
  opacity: 1;
  pointer-events: all;
}
.reaction__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
}
.reaction .title {
  margin: 0 0 40px 0;
  font-weight: 400;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .reaction .title {
    margin: 0 0 90px 0;
  }
}
.reaction .tns-ovh {
  overflow: visible;
}
.reaction .tns-controls {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  column-gap: 20px;
}
.reaction .tns-controls button {
  background: transparent;
  cursor: pointer;
}
.reaction .tns-controls button[disabled] {
  opacity: 0.3;
  cursor: auto;
}

.anchor {
  padding: 40px 0;
}
@media only screen and (min-width: 1024px) {
  .anchor {
    padding: 80px 0;
  }
}
.anchor__col {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .anchor__col {
    width: calc(33.3333333333% - 10px);
  }
}
.anchor__col--content {
  display: flex;
  align-items: center;
}
.anchor__img {
  width: 100%;
}
.anchor__link {
  display: block;
  padding: 10px 0;
  transition: color 0.3s ease-out;
  color: #283333;
  font-family: "Syne";
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .anchor__link:hover {
    color: #686E63;
  }
}
.anchor__list {
  width: 100%;
}
.anchor__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.video,
.doc,
.link {
  padding: 40px 0;
}
.video__figure,
.doc__figure,
.link__figure {
  position: relative;
  flex: 0 0 350px;
  margin: 0 0 0 20px;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .video__figure,
  .doc__figure,
  .link__figure {
    flex: 1 0 100%;
    margin: 20px 0 0 0;
  }
}
.video__figureImg,
.doc__figureImg,
.link__figureImg {
  width: 100%;
}
.video__figure svg,
.doc__figure svg,
.link__figure svg {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video__item,
.doc__item,
.link__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 0;
  padding: 20px 30px;
  background: #F7F0EB;
}
@media only screen and (min-width: 1024px) {
  .video__item,
  .doc__item,
  .link__item {
    margin-left: 95px;
  }
}
@media only screen and (max-width: 1024px) {
  .video__item,
  .doc__item,
  .link__item {
    flex-wrap: wrap;
  }
}
.video__link,
.doc__link,
.link__link {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  color: #283333;
}
@media only screen and (min-width: 1024px) {
  .video__link,
  .doc__link,
  .link__link {
    margin-left: 95px;
  }
}
.video__link svg,
.doc__link svg,
.link__link svg {
  display: block;
  flex: 0 0 20px;
  height: auto;
  margin: 0 10px 0 0;
}
.video__picto,
.doc__picto,
.link__picto {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  margin: 30px 0;
}
.video__img,
.doc__img,
.link__img {
  display: block;
  width: auto;
  max-height: 65px;
  margin: 0 0 20px 0;
}
.video__text,
.doc__text,
.link__text {
  display: flex;
  align-items: center;
}
.video__text svg,
.doc__text svg,
.link__text svg {
  display: block;
  flex: 0 0 20px;
  height: auto;
  margin: 0 10px 0 0;
}
.video__title,
.doc__title,
.link__title {
  margin: 0 0 10px 0;
}
.video__title svg,
.doc__title svg,
.link__title svg {
  display: inline-block;
  width: auto;
  height: 50px;
  margin: -9px 35px 0 0;
  vertical-align: top;
}
@media only screen and (max-width: 767px) {
  .video__title svg,
  .doc__title svg,
  .link__title svg {
    width: 30px;
    height: auto;
    margin: -7px 15px 0 0;
  }
}

.programme {
  padding: 40px 0;
  background: #F7F0EB;
}
@media only screen and (min-width: 1024px) {
  .programme {
    padding: 80px 0;
  }
}
.programme__picto {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
}
.programme__img {
  display: block;
  margin: 0 0 20px 0;
}
.programme__text {
  margin: 40px 0;
  text-align: center;
}
.programme svg, .programme__image {
  display: block;
  width: 100%;
  max-width: 255px;
  margin: 0 auto;
}